var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.ordem_servicos,"items-per-page":-1,"sort-by":"id","sort-desc":true,"hide-default-footer":""},on:{"click:row":_vm.iniciarBaixaOs},scopedSlots:_vm._u([{key:"item.previsao_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.previsao_inicio,"dd/MM/yyyy"))+" ")]}},{key:"item.previsao_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.previsao_fim,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")((item.valor - item.desconto)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")((item.valor - item.desconto)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 5)?_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"green lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.finalizado"))+" ")],1):_vm._e()]}}])}),(_vm.dialogBaixaOs)?_c('DialogBaixaOs',{attrs:{"dialogBaixaOs":_vm.dialogBaixaOs,"ordem_servico":_vm.ordem_servico,"ordem_servico_parcelas":_vm.ordens_servicos_parcelas},on:{"update:dialogBaixaOs":function($event){_vm.dialogBaixaOs=$event},"update:dialog-baixa-os":function($event){_vm.dialogBaixaOs=$event},"fetch-ordens-servicos":_vm.getOrdemServicos}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }