<template>
  <v-card outlined>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="ordem_servicos"
      :items-per-page="-1"
      sort-by="id"
      :sort-desc="true"
      hide-default-footer
      class="data-tables"
      @click:row="iniciarBaixaOs"
    >
      <template v-slot:item.previsao_inicio="{ item }">
        {{ item.previsao_inicio | dateFormat("dd/MM/yyyy") }}
      </template>
      <template v-slot:item.previsao_fim="{ item }">
        {{ item.previsao_fim | dateFormat("dd/MM/yyyy") }}
      </template>
      <template v-slot:item.valor="{ item }">
        {{ item.moeda_sigla }}
        <span v-if="item.moeda_sigla === 'G$'">
          {{ item.valor | guarani }}
        </span>
        <span v-else>
          {{ item.valor | currency }}
        </span>
      </template>
      <template v-slot:item.desconto="{ item }">
        {{ item.moeda_sigla }}
        <span v-if="item.moeda_sigla === 'G$'">
          {{ item.desconto | guarani }}
        </span>
        <span v-else>
          {{ item.desconto | currency }}
        </span>
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.moeda_sigla }}
        <span v-if="item.moeda_sigla === 'G$'">
          {{ (item.valor - item.desconto) | guarani }}
        </span>
        <span v-else>
          {{ (item.valor - item.desconto) | currency }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-center" v-if="item.status === 5">
          <v-sheet
            width="10px"
            height="10px"
            color="green lighten-1"
            style="border-radius: 3px"
            class="mr-2"
          >
          </v-sheet>
          {{ $tc("global.finalizado") }}
        </div>
      </template>
    </v-data-table>
    <DialogBaixaOs
      v-if="dialogBaixaOs"
      :dialogBaixaOs.sync="dialogBaixaOs"
      :ordem_servico="ordem_servico"
      :ordem_servico_parcelas="ordens_servicos_parcelas"
      @fetch-ordens-servicos="getOrdemServicos"
    />
  </v-card>
</template>

<script>
import { fetchOrdensServicoFinalizados } from "@/api/ordem-servico/ordem_servicos.js";
import { iniciaBaixaOs } from "@/api/ordem-servico/ordem_servicos.js";

export default {
  name: "ListaOs",

  props: {},

  components: {
    DialogBaixaOs: () => import("./DialogBaixaOs.vue"),
  },

  data() {
    return {
      ordem_servicos: [],
      loading: false,
      dialogBaixaOs: false,
      ordens_servicos_parcelas: [],
      ordem_servico: {},
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "id",
          value: "id",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.previsaoInicio"),
          value: "previsao_inicio",
        },
        {
          text: this.$tc("global.previsaoFim"),
          value: "previsao_fim",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },
  },

  methods: {
    getOrdemServicos() {
      this.loading = true;
      return fetchOrdensServicoFinalizados()
        .then((response) => {
          if (response) {
            this.ordem_servicos = response;
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async iniciarBaixaOs(item) {
      await iniciaBaixaOs(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.ordem_servico = response.data.result;
            if (this.ordem_servico.parcelas) {
              this.ordens_servicos_parcelas = this.ordem_servico.parcelas;
            }
            this.dialogBaixaOs = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$toast.error(this.$tc("global.vendaBaixa"));
          }
        });
    },
  },

  async mounted() {
    await this.getOrdemServicos();
  },
};
</script>

<style>
</style>